import React from 'react'
import { Link } from 'gatsby'

export default () => {
  return (
    <header>
      <div className="container">
        <nav>
          <Link to="/">
            Terminal Sound System
          </Link>
          <Link to="/bio/">
            Bio
          </Link>
          <Link to="/releases/">
            Releases
          </Link>
          <Link to="/contact/">
            Contact
          </Link>
          <a href="https://terminalsoundsystem.bandcamp.com">
            Store
          </a>
        </nav>
      </div>
    </header>
  )
}
