import React from 'react'
import { Helmet } from 'react-helmet'
import Header from './header'
import Footer from './footer'

export default ({title, description, image, children}) => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          {title ? `${title} | ` : ``}
          Terminal Sound System
        </title>
        <meta name="description" content={description ? description : 'Terminal Sound System is Australia-based musician/producer Skye Klein and an army of robots, keeping time for Armageddon.'} />
        <meta property="og:image" content={image ? `https://antisound.net${image}` : `https://terminalsoundsystem.com/images/bg-space.jpg`} />
        <link rel="stylesheet" href="/styles.css" />
      </Helmet>
      
      <Header
      />

      {children && (
        <main className="main">
          <div className="container">
            {children}
          </div>
        </main>
      )}

      <Footer
      />
    </>
  )
}
